body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

.logo {
  background: url("https://auth.amazefcc233.com/img/casdoor-logo_1185x256.png");
  background-size: 130px, 27px !important;
  width: 130px;
  height: 27px;
  margin: 17px 0 16px 15px;
  float: left;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle thead > tr > th,
.ant-table.ant-table-middle tbody > tr > td {
  padding: 1px 8px !important;
}

.ant-list-sm .ant-list-item {
  padding: 2px !important;
}

.ant-drawer-body {
  padding: 0 !important;
  overflow: hidden !important;
}

.custom-link:hover {
  color: rgb(64 64 64) !important;
}
